<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex items-center space-x-2">
            <router-link :to="{ name: 'estate.pos.stationOrder'}" target="_blank" class="items-center flex">
              <vs-button v-if="!selectable && $can('create_order')" color="primary" type="border" icon-pack="feather" icon="icon-monitor" class="px-3">Station Order</vs-button>
            </router-link>
            <vs-button v-if="!selectable" color="primary" :type="isEmptyFilter ? 'flat' : 'filled'" icon-pack="feather" icon="icon-filter" class="px-3" @click="modalFilter.active = true">Filter</vs-button>
            <vs-button color="primary" type="flat" icon-pack="feather" icon="icon-refresh-cw" class="px-3" @click="getData">Refresh</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap">Proyek</vs-th>
          <vs-th class="whitespace-no-wrap">Nomor</vs-th>
          <vs-th class="whitespace-no-wrap">Tanggal</vs-th>
          <vs-th class="whitespace-no-wrap">Total</vs-th>
          <vs-th class="whitespace-no-wrap">Nama Customer</vs-th>
          <vs-th class="whitespace-no-wrap">HP</vs-th>
          <vs-th class="whitespace-no-wrap">E-mail</vs-th>
          <vs-th class="whitespace-no-wrap">Status</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap">Created By</vs-th>
          <vs-th class="whitespace-no-wrap">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td v-if="!selectable">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer" v-if="$canAny(['delete_order']) && item.id_jurnal === null">
                <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                <vs-dropdown-menu>
                  <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)" v-if="$can('delete_order')"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.kode_proyek }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_penjualan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_penjualan }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.hp }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.email }}</vs-td>
            <vs-td class="whitespace-no-wrap"><span class="text-xs text-primary"> {{ item.status }} </span></vs-td>
            <vs-td class="">{{ item.keterangan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.username }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <PenjualanFilter v-if="!selectable" :isActive.sync="modalFilter.active" @filter="onFilter"/>
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import PenjualanRepository from '@/repositories/estate/pos-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Penjualan',
  components: {
    ImageViewer,
    PagingLimit,
    PenjualanFilter: () => import('@/views/pages/estate/pos/PosFilter'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv')
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      filter: {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      },
      modalFilter: {
        active: false
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      // params.fromDate = this.filter.fromDate
      // params.toDate = this.filter.toDate

      params.filter = JSON.stringify({
        only_authorized_user: 1,
        ...this.filter
      })

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      PenjualanRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      PenjualanRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    onFilter (filterData) {
      this.filter = filterData
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showImage (image) {
      this.$refs.viewer.show([image])
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
